import React, { useEffect, useRef } from 'react';
import './Star.css';

const Star = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const stars = [];
    const numStars = 200; // Number of stars
    const radius = 1; // Adjust size of stars

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      drawStaticStars();
    };

    const drawStaticStars = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'white';
      for (let i = 0; i < numStars; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.fill();
      }
    };

    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };

    const debouncedResizeCanvas = debounce(resizeCanvas, 200);

    resizeCanvas();
    window.addEventListener('resize', debouncedResizeCanvas);

    return () => {
      window.removeEventListener('resize', debouncedResizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} id="starfield"></canvas>;
};

export default Star;
