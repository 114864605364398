import React from 'react';
import './footer.css';
import hiddencat from '../assets/hiddencat.gif'; // Adjust the path as needed

const Footer = () => {
  return (
    <footer className="footer">
      <div className="gif-container">
        <img src={hiddencat} alt="Hidden Cat" className="hiddencat-gif" />
      </div>
      <div className="copyright">
        &copy; 2024 Zakron IT Industry. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
