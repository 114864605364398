import React from 'react';
import './TrainingOpportunities.css';

const TrainingOpportunities = () => {
  return (
    <section id="training" className="training">
      <h2 className="training-title">Training Opportunities</h2>
      <p>Our programs provide essential training and real-world project experience, bridging the gap between fresh talent and industry demands.</p>
      <div className="courses">
        <div className="course-card">
          <h3>Business Development</h3>
          <p><ul>
            <li>Comprehensive 9-module course</li>
            <li>1-month internship</li>
            <li>Includes live lessons</li>
            <li>Includes doubt-clearing sessions</li>
            </ul>
          </p>
        </div>
        <div className="course-card">
          <h3>Artificial Intelligence</h3>
          <p><ul>
            <li>Advanced 9-module course</li>
            <li>1-month internship</li>
            <li>Includes live lessons</li>
            <li>Includes doubt-clearing sessions</li>
          </ul>
          </p>
        </div>
        <div className="course-card">
          <h3>Cyber Security</h3>
          <p><ul>
  <li>Intensive 9-module course covering both software and hardware</li>
  <li>1-month internship</li>
  <li>Includes live lessons</li>
  <li>Includes doubt-clearing sessions</li>
</ul>
</p>
        </div>
        <div className="course-card">
          <h3>Digital Marketing</h3>
          <p><ul>
  <li>Specialized 9-module course</li>
  <li>1-month internship</li>
  <li>Includes live lessons</li>
  <li>Includes doubt-clearing sessions</li>
</ul>
</p>
        </div>
      </div>
    </section>
  );
};

export default TrainingOpportunities;
