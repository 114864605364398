import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './ContactUs.css';
import catGif from '../assets/gifcat.gif'; // Ensure you have the cat GIF in the specified path

const ContactUs = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    fixMeeting: false,
  });

  const onSubmit = (data) => {
    console.log(data);
    alert('Form submitted successfully!');
  };

  return (
    <section className="contact-us-section" id="contact">
      <div className="contact-us-card">
        <div className="contact-us-left">
          <img src={catGif} alt="Cat GIF" className="cat-gif" />
        </div>
        <div className="contact-us-right">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input 
                type="text" 
                id="name" 
                {...register('name', { required: 'Name is required*' })} 
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="email" 
                id="email" 
                {...register('email', { 
                  required: 'Email is required*', 
                  pattern: { 
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 
                    message: 'Invalid email address' 
                  } 
                })} 
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message" 
                {...register('message', { required: 'Message is required' })} 
              ></textarea>
              {errors.message && <p>{errors.message.message}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="fixMeeting">Fix a Meeting?</label>
              <input 
                type="checkbox" 
                id="fixMeeting" 
                {...register('fixMeeting')} 
              />
            </div>
            <button type="submit" className="contact-button">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
