import React from 'react';
import './navigation.css';
import logo from '../assets/logo.png';

const Navigation = () => {
  return (
    <header className="header">
      <nav className="nav-left">
        <ul>
          <li><a href="#about-us">About Us</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#training">Training Opportunities</a></li>
          <li><a href="#blogs">Blog</a></li>
          <img src={logo} alt="Zakron IT Industries" className="logo" />
        </ul>
      </nav>
      
      <nav className="nav-right">
        <ul>
          <li><a href="#contact" className="contact-button">Contact Us</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
