// AboutUs.js
import React from 'react';
import './AboutUs.css';

import encryption from '../assets/encryption.webm'; // Import the background video

const AboutUs = () => {
  return (
    <div className="about-us" section id="about-us">
      <div className="about-us-content">

        <h1 className="about-title">About Us</h1>
        <p className="about-description">
          We are a company that provides digital marketing, website and software development, and special custom AI software solutions. Our mission is to transform your business with innovative solutions. Our team is composed of skilled professionals who are passionate about technology and committed to delivering top-notch services. We believe in a collaborative approach, working closely with our clients to understand their unique needs and goals.
        </p>
    
      </div>
      <div className="separator-line"></div> {/* Add separator line */}
      <div className="services" section id="services">
        <h2 className="services-title">Our Services</h2>
        <div className="video-background-container">
          <video className="video-background" autoPlay muted loop>
            <source src={encryption} type="video/webm" />
          </video>
          <div className="services-container">
            <div className="service-card">
              <h3>Digital Marketing</h3>
              <ul>
                <li>Enhance your online presence</li>
                <li>Boost engagement with creative content</li>
                <li>Maximize ROI with targeted campaigns</li>
              </ul>
            </div>
            <div className="service-card">
              <h3>Website Development</h3>
              <ul>
                <li>Build a stunning and responsive website</li>
                <li>Custom designs to suit your brand</li>
                <li>User-friendly and SEO optimized</li>
              </ul>
            </div>
            <div className="service-card">
              <h3>Custom AI Software</h3>
              <ul>
                <li>Streamline business processes</li>
                <li>Leverage AI for better decision making</li>
                <li>Tailored solutions for unique needs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
