// Homepage.js
import React from 'react';
import './HomePage.css';
import blackhole from '../assets/blackhole.webm';
import Navigation from './navigation';
import AboutUs from './AboutUs'; // Import AboutUs component
import Star from './star'; // Import Star component
import TrainingOpportunity from './TrainingOpportunities'; // Import TrainingOpportunity component
import ContactUs from './ContactUs'; // Import ContactUs component
import Footer from './Footer';

const Homepage = () => {
  return (
    <div className="homepage">
      <Navigation />
      <Star />
      <div className="video-container">
        <video
          className="rotate-180 absolute top-0 left-0 h-full w-full z-[1] object-cover"
          autoPlay
          muted
          loop
        >
          <source src={blackhole} type="video/webm" />
        </video>
      </div>
      <div className="content">
        <h1 className="title">Transforming Your Business with <span className="highlight">Innovative Solutions</span></h1>
        <p className="subtitle">We provide digital marketing, website and software development, and also special custom AI software!</p>
        <button className="learn-more">Contact Us!</button>
      </div>
      <AboutUs /> {/* Include AboutUs component */}
      <TrainingOpportunity /> {/* Include TrainingOpportunity component */}
      <ContactUs /> {/* Include ContactUs component */}
      <Footer />
    </div>
  );
};

export default Homepage;
